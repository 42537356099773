import React, { useContext, useEffect, useState } from "react";
import { Box, Text, Button, Flex, Title } from "@mantine/core";
import SettingContext from "../../contexts/Setting/settingContext";
import SocialNetworks from "../../components/Settings/SocialNetworks";
import Branches from "../../components/Settings/Branches";
import WhatsappView from "../../components/Settings/WhatsappView";
import AuthContext from "../../contexts/Auth/authContext";
const Settings = () =>
{
  const settingContext = useContext(SettingContext);
  const { settings, getSettings, updateSettings, loading } = settingContext;
  const authContext = useContext(AuthContext);
  const { user } = authContext;
  const [ localSettings, setLocalSettings ] = useState({
    socialNetworks: [],
    branches: [],
    modules: [],
    privacyStatement: "",
    contactEmail: "",
    whatsapps: []
  });

  // Obtener la configuración al montar el componente
  useEffect(() =>
  {
    getSettings();
  }, [loading]);

  // Actualizar el estado local cuando cambia 'settings'
  useEffect(() =>
  {
    if (settings?._id) {
      setLocalSettings(settings);
    }
  }, [ settings ]);
  const hasRole = (role) => user.role.includes(role);
  // Función para manejar cambios en redes sociales
  const handleInputChange = (index,field, value) =>
  {
    const updatedNetworks = localSettings.socialNetworks.map((network, idx) =>
    {
      if (idx === index) {
        return { ...network, [ field ]: value };  // Actualiza el campo específico del objeto
      }
      return network;
    });

    setLocalSettings(prevState => ({
      ...prevState,
      socialNetworks: updatedNetworks
    }));
  };

  // Funciones para manejar cambios en las sucursales y WhatsApp (implementar lógica específica si es necesario)
  const handleBranchesChange = (index,field, value) =>
  {
    const branches = localSettings.branches.map((item, idx) =>
    {
      if (idx === index) {
        return { ...item, [ field ]: value };  // Actualiza el campo específico del objeto
      }
      return item;
    });

    setLocalSettings(prevState => ({
      ...prevState,
      branches: branches
    }));
  };

  const handleWhatsappChange = (index,field, value) =>
  {
    const whatsapps = localSettings.whatsapps.map((item, idx) =>
    {
      if (idx === index) {
        return { ...item, [ field ]: value };  // Actualiza el campo específico del objeto
      }
      return item;
    });

    setLocalSettings(prevState => ({
      ...prevState,
      whatsapps: whatsapps
    }));
  };

  // Función para actualizar la configuración
  const updateInfo = async () =>
  {
    await updateSettings(localSettings._id,localSettings);
  };

  return (
    <>
      <div className="grid grid-cols-1 md:flex md:flex-row justify-between items-start md:items-center gap-1">
        <Title order={ 1 }>Configuración</Title>
        <Flex direction={ { base: 'column', md: 'row' } } gap="sm">
         {hasRole('ADMIN') && <Button onClick={ updateInfo } color="blue" autoContrast variant="filled" size="md" radius="md" className="w-full sm:w-48">
            Guardar
          </Button>}
        </Flex>
      </div>
      <Box className="grid grid-cols-1 md:grid-cols-2 gap-2">
        <SocialNetworks localSettings={ localSettings } handleInputChange={ handleInputChange } />
        <Branches localSettings={ localSettings } handleInputChange={ handleBranchesChange } />
        <WhatsappView localSettings={ localSettings } handleInputChange={ handleWhatsappChange } />
      </Box>
    </>
  );
};

export default Settings;
