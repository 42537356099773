import { useContext } from 'react'
import { Text, Box, Divider } from '@mantine/core'
import SettingContext from '../../contexts/Setting/settingContext'

const TicketFooter = () =>
{
  const settingContext = useContext(SettingContext);
  const { settings } = settingContext;
  return (
    <div>
      <Box className='hidden print:block'>
        <Box className='flex flex-col gap-2 items-center justify-center my-2'>
          <Text fz="xs" className='hidden print:block text-center ticketFont capitalize'>Ubicación</Text>
          <Text fz="xs" className='hidden print:block text-center ticketFont'>{ settings?.branches[ 0 ].address }</Text>
        </Box>

        <Text fz="xs" className='hidden print:block text-center ticketFont'>Teléfono</Text>
        <Box className='flex flex-row gap-2 items-center justify-center my-1'>
          <Text fz="xs" className='hidden print:block text-center ticketFont'>{ settings?.branches[ 0 ].phone1 }</Text>
          <Text fz="xs" className='hidden print:block text-center ticketFont'>{ settings?.branches[ 0 ].phone2 }</Text>
        </Box>
        <Box className='pb-8 mb-4 flex flex-col'>
          <Text fz="xs" className='hidden print:block text-center ticketFont'>Horarios</Text>
          <Box className='flex flex-col gap-2 items-center justify-center'>
            <Text fz="xs" className='hidden print:block text-center ticketFont'>{ settings?.branches[ 0 ].schedule1 }</Text>
            <Text fz="xs" className='hidden print:block text-center ticketFont'>{ settings?.branches[ 0 ].schedule2 }</Text>
            <br />
          </Box>
        </Box>
      </Box>
      <br />
      <br />
      <br />
      <br />
      <Divider my={ 2 } className='text-gray-300' />
    </div>
  )
}

export default TicketFooter